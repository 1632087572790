import { useEffect, useState } from 'react'

/** This display mode is set in the public/manifest.json */
const mqStandAlone = '(display-mode: standalone)'

export function useIsPwaOpen(): boolean {
  return useMediaQuery(mqStandAlone)
}

/** Hook to determine whether the given media query is currently applied. */
function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(false)
  useEffect(() => {
    const matchQueryList = window.matchMedia(query)
    function handleChange(e: MediaQueryList) {
      setMatches(e.matches)
    }
    matchQueryList.addEventListener('change', handleChange as any)
    return () => {
      matchQueryList.removeEventListener('change', handleChange as any)
    }
  }, [query])
  return matches
}

// Below hook to 'Add to homescreen' adapted from
// https://gist.github.com/rikukissa/cb291a4a82caa670d2e0547c520eae53

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}

/**
 * Example usage:
 * `const [promptable, promptToInstall, wasInstalled] = useAddToHomescreenPrompt()`
 */
export function useAddToHomescreenPrompt(): [
  IBeforeInstallPromptEvent | null,
  () => void,
  boolean
] {
  const [promptable, setPromptable] =
    useState<IBeforeInstallPromptEvent | null>(null)

  const [wasInstalled, setWasInstalled] = useState(false)

  const promptToInstall = () => {
    if (promptable) {
      return promptable.prompt()
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    )
  }

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault()
      setPromptable(e)
    }

    window.addEventListener('beforeinstallprompt', ready as any)

    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any)
    }
  }, [])

  useEffect(() => {
    const onInstall = () => {
      setWasInstalled(true)
    }

    window.addEventListener('appinstalled', onInstall as any)

    return () => {
      window.removeEventListener('appinstalled', onInstall as any)
    }
  }, [])

  return [promptable, promptToInstall, wasInstalled]
}
